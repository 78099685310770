var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{staticClass:"col-md-12",staticStyle:{"background":"#eee"},attrs:{"align":"right"}},[_c('v-btn',_vm._g(_vm._b({staticClass:"mb-4",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.form_arancel = true}}},'v-btn',attrs,false),on),[_vm._v(" Nuevo Arancel ")])],1)]}}]),model:{value:(_vm.form_arancel),callback:function ($$v) {_vm.form_arancel=$$v},expression:"form_arancel"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.editarArancel ? "Editar" : "Nuevo")+" Arancel")]),_c('v-spacer'),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.form_arancel = false}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("close")])],1)]}}])})],1),(_vm.procensando_datos)?_c('div',[_c('loader',{attrs:{"object":"#4caf50","color1":"#ffffff","color2":"#17fd3d","size":"5","speed":"2","bg":"#343a40","objectbg":"#999793","opacity":"40","disableScrolling":"false","name":"box"}})],1):_vm._e(),_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"counter":30,"label":"Coeficiente","hint":"Ejemplo: 0.1234","rules":[function (v) { return !!v || 'Un Importe es requerido'; }],"required":""},model:{value:(_vm.Importe),callback:function ($$v) {_vm.Importe=$$v},expression:"Importe"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Importe Mínimo","prefix":"$","type":"number","min":"0","step":"1","rules":[
                        function (v) { return !!v || 'Un Importe mínimo es requerido'; } ],"required":""},model:{value:(_vm.Importeminimo),callback:function ($$v) {_vm.Importeminimo=$$v},expression:"Importeminimo"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Importe Máximo","prefix":"$","type":"number","min":"0","step":"1","rules":[
                        function (v) { return !!v || 'Un Importe máximo es requerido'; } ],"required":""},model:{value:(_vm.Importemaximo),callback:function ($$v) {_vm.Importemaximo=$$v},expression:"Importemaximo"}})],1)],1),_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":"","rules":[
                        function (v) { return !!v || 'Un Tipo de Calculo es requerido'; } ]},model:{value:(_vm.Tipocalculo),callback:function ($$v) {_vm.Tipocalculo=$$v},expression:"Tipocalculo"}},[_c('v-radio',{attrs:{"value":"PORCENTAJE"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('strong',{staticClass:"primary--text"},[_vm._v("PORCENTAJE")])])]},proxy:true}])}),_c('v-radio',{attrs:{"value":"FIJO"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_c('strong',{staticClass:"primary--text"},[_vm._v("FIJO")])])]},proxy:true}])})],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.rubros,"item-text":"rubro","item-value":"id","label":"Rubro","rules":[function (v) { return !!v || 'Un Rubro es requerido'; }],"required":""},model:{value:(_vm.RubrosId),callback:function ($$v) {_vm.RubrosId=$$v},expression:"RubrosId"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.canales,"item-text":"Nombre","item-value":"Id","label":"Canal de Pago","rules":[function (v) { return !!v || 'Un Canal de Pago es requerido'; }],"required":""},on:{"change":function () { return _vm.ChannelsId === 1
                            ? (_vm.showRadios = true)
                            : (_vm.showRadios = false); }},model:{value:(_vm.ChannelsId),callback:function ($$v) {_vm.ChannelsId=$$v},expression:"ChannelsId"}})],1)],1),_c('v-row',[(_vm.showRadios)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" Seleccione si la comisión del canal de pago crédito es para una o varias cuotas. ")])]},proxy:true}],null,false,555057577),model:{value:(_vm.Pagocuota),callback:function ($$v) {_vm.Pagocuota=$$v},expression:"Pagocuota"}},[_c('v-radio',{attrs:{"value":"false"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',{staticClass:"primary--text"},[_vm._v("Una")]),_vm._v(" cuota ")]},proxy:true}],null,false,1174268698)}),_c('v-radio',{attrs:{"value":"true"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('strong',{staticClass:"primary--text"},[_vm._v("Varias")]),_vm._v(" cuotas ")]},proxy:true}],null,false,1250241357)})],1)],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.medios,"item-text":"mediopago","item-value":"ID","label":"Medios de pago (opcional)","required":""},model:{value:(_vm.mediopago_id),callback:function ($$v) {_vm.mediopago_id=$$v},expression:"mediopago_id"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":true,"transition":"scale-transition","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"prepend-icon":"mdi-calendar","label":"Vigencia desde","readonly":"","persistent-hint":"","value":_vm.Fechadesde.length > 0
                              ? _vm.formatDate(_vm.Fechadesde)
                              : '',"rules":[
                            function (v) { return !!v || 'Una Fecha de Vigencia es requerido'; } ],"required":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"es-ar","no-title":"","dark":"","scrollable":""},model:{value:(_vm.Fechadesde),callback:function ($$v) {_vm.Fechadesde=$$v},expression:"Fechadesde"}})],1)],1)],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.form_arancel = false}}},[_vm._v(" Cerrar ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.guardar_arancel()}}},[_vm._v(" Guardar ")])],1)],1)],1)],1),_c('v-app',[_c('v-main',[_c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header card-header-success"},[_c('h4',{staticClass:"card-title"},[_vm._v("Aranceles")]),_c('p',{staticClass:"card-category"})]),(_vm.errored)?_c('section',[_c('v-alert',{staticClass:"mt-3 mx-3 elevation-3",attrs:{"border":"left","color":"red lighten-2","dark":""}},[_vm._v(" Lo sentimos, no es posible obtener la información en este momento, por favor intente nuevamente mas tarde. ")])],1):_c('section',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","clearable":"","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0 trow",attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.aranceles,"loading":_vm.cargando,"items-per-page":_vm.rowsPerPage,"custom-filter":_vm.customFilter,"hide-default-footer":"","loading-text":"Cargando... Espere por favor","no-results-text":"No se encontraron registros","no-data-text":"No se encontraron registros"},scopedSlots:_vm._u([{key:"item.RubrosId",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nombreRubro(item))+" ")]}},{key:"item.ChannelsId",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nombreCanal(item))+" ")]}},{key:"item.Importe",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(item.Tipocalculo == "PORCENTAJE" ? _vm.decimales(item) + " %" : "$ " + _vm.decimales(item))+" ")]}},{key:"item.Fechadesde",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.Fechadesde))+" ")]}},{key:"item.Mediopagoid",fn:function(ref){
                          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.nombreMedio(item))+" ")]}},{key:"item.actions",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{attrs:{"color":"orange"},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" create ")])]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"no-gutters mt-2 mx-2"},[_c('v-flex',{attrs:{"xs12":""}},[_c('span',[_vm._v("Mostrar")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.rowsPerPage)+" "),_c('v-icon',[_vm._v("arrow_drop_down")])],1)]}}])},[_c('v-list',_vm._l((_vm.rowsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateRowsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1)],1)],1)]},proxy:true}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pagination.total,"total-visible":_vm.pagination.visible},on:{"input":_vm.onPageChange},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1)],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }