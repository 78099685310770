<template>
  <v-dialog v-model="dialog" max-width="500px" @click:outside="closeDialog">
    <v-card class="pa-3 rounded-lg elevation-3">
      <!-- Título -->
      <div style="font-family: 'Montserrat' !important; font-weight: bold;">
        <v-row class="align-center mb-0">
          <v-col cols="11" class="flex justify-center"> Configuración </v-col>
          <!-- >{{ cuenta }} -->
          <v-col cols="1" class="flex justify-end">
            <v-menu bottom left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="closeDialog"
                  class="hover-red"
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </template>
            </v-menu>
          </v-col>
        </v-row>
      </div>

      <v-divider></v-divider>

      <div>
        <!-- Bloqueo Tarjeta y Usuario -->
        <v-row class="mt-2">
          <v-col cols="6">
            <v-checkbox
              v-model="bloqTarjeta"
              @change="cambiarCero"
              class="ckeck"
              label="Bloqueo Tarjeta"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="intentosTarjeta"
              ref="intentosTarjetaField"
              :min="0"
              :loading="cargando"
              prepend-inner-icon="mdi-credit-card-lock-outline"
              type="number"
              :rules="[(v) => v >= 0 || 'El valor tiene que ser mayor a cero']"
              label="Intentos Tarjeta"
              :disabled="!bloqTarjeta"
              dense
              outlined
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-checkbox
              v-model="bloqUser"
              @change="cambiarCero"
              class="ckeck"
              label="Bloqueo Usuario"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="intentosUser"
              ref="intentosUserField"
              :loading="cargando"
              :min="0"
              :rules="[(v) => v >= 0 || 'El valor tiene que ser mayor a cero']"
              prepend-inner-icon="mdi-account-lock-outline"
              type="number"
              label="Intentos Usuario"
              :disabled="!bloqUser"
              dense
              outlined
            ></v-text-field>
          </v-col>
        </v-row>

        <v-divider class="my-3"></v-divider>

        <!-- Otras opciones -->
        <v-row>
          <v-col cols="6">
            <v-checkbox
              v-model="bloqRef"
              class="ckeck"
              label="Bloqueo Referencia"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-model="controlDni"
              class="ckeck"
              label="Control DNI"
              dense
            ></v-checkbox>
          </v-col>
          <v-col cols="12">
            <v-checkbox
              v-model="tarjetasInter"
              class="ckeck"
              label="Tarjetas Internacionales"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
      </div>

      <!-- Acciones -->
      <v-card-actions class="flex justify-end">
        <v-btn text color="grey" @click="closeDialog">Cancelar</v-btn>
        <v-btn
          v-if="!nuevaConfiguracion"
          color="blue"
          text
          :loading="cargando"
          @click="guardarCambios"
          >Guardar</v-btn
        >
        <v-btn
          v-else
          color="blue"
          text
          :loading="cargando"
          @click="guardarCambios"
          >Crear</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { ConfiguracionCuenta } from "@/services/configuracion-cuenta";

export default {
  name: "DialogConfiguracion",

  data() {
    return {
      procensando_datos: false,
      dialog: false,

      configuracion: [],
      bloqTarjeta: false,
      bloqUser: false,
      bloqRef: false,
      controlDni: false,
      tarjetasInter: false,

      intentosTarjeta: 0,
      intentosUser: 0,
      intenUserAnt: 0,
      intenTarjAnt: 0,

      cargando: false,

      nuevaConfiguracion: false,

      cuenta: "",
      id: "",
    };
  },
  mounted() {
    this.$on("showDialogConfiguracion", (item) => {
      this.dialog = true;
      this.cuentas_id = item.id;
      this.cuenta = item.cuenta;
      this.getConfiguracionCuentaData();
    });
  },
  methods: {
    cambiarCero() {
      if (!this.bloqUser) {
        this.intentosUser = 0;
      } else {
        this.intentosUser = this.intenUserAnt;
      }

      if (!this.bloqTarjeta) {
        this.intentosTarjeta = 0;
      } else {
        this.intentosTarjeta = this.intenTarjAnt;
      }
    },

    async guardarCambios() {
      this.cargando = true;
      const tarjetaValid = this.$refs.intentosTarjetaField.validate();
      const userValid = this.$refs.intentosUserField.validate();
      if (tarjetaValid && userValid) {
        if (this.nuevaConfiguracion) {
          this.crearConfiguracion();
        } else {
          this.editarConfiguracionCuenta();
        }

        this.nuevaConfiguracion = false;
      }
      this.cargando = false;
    },

    //editar o crear contacto
    guardarOptions() {
      this.editar
        ? this.editarConfiguracionCuenta()
        : this.crearConfiguracion();
    },

    // ---------------------------dialog Configuracion------------------------
    //cerrar dialog
    closeDialog() {
      this.dialog = false;
      this.configuracion = [];
    },

    //VALIDAR FORMULARIO
    validate() {
      this.$refs.form.validate();
      this.res = this.$refs.form.validate();
    },

    async habilitarNuevaConfiguracion() {
      if (this.configuracion.id === 0) {
        this.nuevaConfiguracion = true;
      } else {
        this.nuevaConfiguracion = false;
      }
    },

    async getConfiguracionCuentaData() {
      const data = {
        CuentaID: this.cuentas_id,
      };

      try {
        this.cargando = true;
        const response = await ConfiguracionCuenta.getConfiguracionCuenta(data);
        this.cargando = false;
        if (response.data.data === null) {
          this.configuracion = [];
          this.$toastr.w(response.data.message);
        } else {
          this.configuracion = response.data.data;

          this.id = this.configuracion.id;
          this.bloqRef = this.configuracion.bloqueo_referencia;
          this.bloqTarjeta = this.configuracion.bloqueo_tarjeta;
          this.bloqUser = this.configuracion.bloqueo_usuario;
          this.controlDni = this.configuracion.control_dni;
          this.intentosTarjeta = this.configuracion.intentos_tarjeta;
          this.intentosUser = this.configuracion.intentos_usuario;

          this.intenUserAnt = this.configuracion.intentos_usuario;
          this.intenTarjAnt = this.configuracion.intentos_tarjeta;

          this.tarjetasInter = this.configuracion.tarjetas_internacionales;
        }
        await this.habilitarNuevaConfiguracion();
        this.cargando = false;
      } catch (error) {
        this.cargando = false;
        this.$toastr.e("Error al obtener la configuracion del contacto");
        if (error.response.data.status === 400) {
          this.$toastr.e(error.response.data.message);
        } else {
          this.$toastr.e("Error al obtener la configuracion del contacto");
        }
      }
    },

    async crearConfiguracion() {
      const payload = {
        cuentas_id: this.cuentas_id,
        bloqueo_usuario: this.bloqUser,
        bloqueo_tarjeta: this.bloqTarjeta,
        intentos_tarjeta: this.bloqTarjeta ? parseInt(this.intentosTarjeta) : 0,
        intentos_usuario: this.bloqUser ? parseInt(this.intentosUser) : 0,
        bloqueo_referencia: this.bloqRef,
        tarjetas_internacionales: this.tarjetasInter,
        control_dni: this.controlDni,
      };

      try {
        this.cargando = true;
        const response = await ConfiguracionCuenta.createConfiguracionCuenta(
          payload
        );
        this.cargando = false;
        if (response.data.status) {
          this.$toastr.s(response.data.message);
        } else {
          this.$toastr.e(response.data.message);
        }
        this.getConfiguracionCuentaData();
      } catch (error) {
        this.cargando = false;
        if (error.response.data.status === 400) {
          this.$toastr.e(error.response.data.message);
        } else {
          this.$toastr.e("Error al crear la configuración");
        }
      }

      this.closeDialog();
    },

    //EDITAR configuracion-cuenta
    async editarConfiguracionCuenta() {
      const payload = {
        id: this.id,
        cuentas_id: this.cuentas_id,
        bloqueo_usuario: this.bloqUser,
        bloqueo_tarjeta: this.bloqTarjeta,
        intentos_tarjeta: this.bloqTarjeta ? parseInt(this.intentosTarjeta) : 0,
        intentos_usuario: this.bloqUser ? parseInt(this.intentosUser) : 0,
        bloqueo_referencia: this.bloqRef,
        tarjetas_internacionales: this.tarjetasInter,
        control_dni: this.controlDni,
      };

      try {
        this.cargando = true;
        const { data } = await ConfiguracionCuenta.putConfiguracionCuenta(
          payload
        );
        this.cargando = false;
        if (data.status === true) {
          this.$toastr.s(data.message);
        } else {
          return this.$toastr.e(data.message);
        }
        this.getConfiguracionCuentaData();
        this.closeDialog();
      } catch (error) {
        this.cargando = false;

        this.$toastr.e("error para cargar configuracion");

        // if (error.response.status === 400) {
        //   this.$toastr.e(error.response.data.message);
        // } else {
        //   this.$toastr.e("Error al editar el contacto");
        // }
      }
    },
  },
};
</script>

<style scoped>
.card-title {
  font-family: "Montserrat" !important;
  font-weight: bold;
}
.hover-red:hover .v-icon {
  color: red !important;
}
</style>
