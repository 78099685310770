import axios from "axios";
import "./axiosInterceptor";
export const PASARELA = process.env.VUE_APP_URLPASARELA;
export const ConfiguracionCuenta = {
    //listar configuracion-cuenta
    getConfiguracionCuenta(params) {
        return axios.get(PASARELA + "/administracion/configuracion-cuenta", { params });
    },

    //actualizar (editar) configuracion-cuenta
    putConfiguracionCuenta(payload) {
        return axios.put(PASARELA + "/administracion/configuracion-cuenta", payload);
    },

    //nueva configuracion-cuenta
    createConfiguracionCuenta(payload) {
        return axios.post(PASARELA + "/administracion/configuracion-cuenta", payload);
    },

    //eliminar configuracion-cuenta
    deleteConfiguracionCuenta(payload) {
        return axios.delete(PASARELA + "/administracion/configuracion-cuenta/:id", {
            data: payload,
        });
    },
};