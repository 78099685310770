import apiCheckout from "../apiCheckout";
import axios from "axios";

const generarCheckout = async (body, apiKey) => {
  const { data } = await apiCheckout.post("/", body, { headers: { apiKey } });

  return data;
};

const generarQR = async (body, apiKey) => {
  const { data } = await apiCheckout.post(`/generar-pago`, body, {
    headers: { apiKey },
  });

  return data;
};

const generarQRFactura = async (body, apiKey) => {
  const { data } = await apiCheckout.post(`/qr-factura`, body, {
    headers: { apiKey },
  });

  return data;
};

const generarQRFacturaProntoPagos = async (body, apiKey) => {
  const { data } = await apiCheckout.post(`/qr-factura-prontopagos`, body, {
    headers: { apiKey },
  });

  return data;
};

const sendQRFactura = async (body, apiKey) => {
  const { data } = await apiCheckout.post(`/send-qr-factura`, body, {
    headers: { apiKey },
  });

  return data;
};

export default { generarCheckout, generarQR, generarQRFactura, generarQRFacturaProntoPagos, sendQRFactura };
